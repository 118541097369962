/* eslint-disable react/no-danger */
/* eslint-disable react/prop-types */
// importSource //

import React, { useEffect, useState } from "react";
import { graphql, useStaticQuery } from "gatsby";
import tw, { css } from "twin.macro";
// import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import moment from "moment";
import { useAuth, useTheme } from "~hooks";
import {
  AccountCard,
  Button,
  Footer,
  Grid,
  JourneySlider,
  StripePaymentCapture
} from "~components";
import { MEDIA_QUERIES } from "~utils/css";

import { ReactComponent as Cross } from "~assets/icons/cross.svg";
import { ReactComponent as Plan } from "~assets/icons/plan.svg";
import { ReactComponent as Token } from "~assets/icons/token-new.svg";
import { ReactComponent as LinkedInIcon } from "~assets/icons/linkedin.svg";
import { ReactComponent as InstagramIcon } from "~assets/icons/instagram.svg";
import { ReactComponent as ConfidenceIcon } from "~assets/icons/confidence-icon.svg";
import { ReactComponent as StackIcon } from "~assets/icons/stack-icon.svg";
import { ReactComponent as TokenIcon } from "~assets/icons/token-icon.svg";

// const TOKEN1 = process.env.GATSBY_STRIPE_PRICE_TOKEN1;
const TOKEN1 = `price_1KbM08CFAqoFBgt2H94DyYrr`;

// const TOKEN2 = process.env.GATSBY_STRIPE_PRICE_TOKEN2;
const TOKEN2 = `price_1KbM0OCFAqoFBgt2YTEjsX1x`;

// const TOKEN5 = process.env.GATSBY_STRIPE_PRICE_TOKEN5;
const TOKEN5 = `price_1KbM0dCFAqoFBgt2ecoM8nTo`;

const Profile = ({ data, location }) => {
  // ==========================================================================
  // gql

  const { sanityAccountPage, sanityRegisterSlides } = useStaticQuery(graphql`
    query ProfilePage {
      sanityAccountPage {
        cancellationHeading
        cancellationNotice
      }

      sanityRegisterSlides {
        slide2JourneyItems
      }
    }
  `);

  const { slide2JourneyItems } = sanityRegisterSlides;

  // ==========================================================================
  // context / ref / state

  const {
    account,
    authUser,
    getInvoices,
    getPaymentMethod,
    loggedIn,
    resetUserPassword,
    stripeCheckout,
    subscribe,
    confirmSubscribe,
    refreshCurrentAccount,
    updateCurrentAccount
  } = useAuth();

  const { colours, colourTransitionCSS, lowImpactMode } = useTheme();

  const [cardInfo, setCardInfo] = useState(null);
  const [form, setForm] = useState({});
  const [updatingPlan, setUpdatingPlan] = useState(false);
  const [refreshCard, setRefreshCard] = useState(false);
  const [saving, setSaving] = useState(false);
  const [purchaseActive, setPurchaseActive] = useState(false);
  const [transactions, setTransactions] = useState([]);
  const [transactionsActive, setTransactionsActive] = useState(false);

  // ==========================================================================
  // variables

  const allGoals = [
    `Improve my confidence`,
    `Learn new skills`,
    `Develop my product`,
    `Join a community`
  ];

  const allSectors = [
    `Fashion & Lifestyle`,
    `Agriculture`,
    `Energy`,
    `Health & Wellness`,
    `Marketing & Advertising`,
    `Architecture`,
    `Design`,
    `Food & Beverage`,
    `Travel`
  ];

  // ==========================================================================
  // methods

  const buyTokens = async (price) => {
    if (typeof window === `undefined`) {
      return;
    }

    const checkout = await stripeCheckout(price);

    // todo: additional validation?
    if (checkout?.data?.url) {
      window.location.href = checkout.data.url;
    }
  };

  //

  const onChange = (e) => {
    setForm({
      ...form,
      [e.currentTarget.name]: e.currentTarget.value
    });
  };

  const onJourneyChange = (journey) => {
    setForm({
      ...form,
      journey
    });
  };

  const toggleGoal = (goal) => {
    const goals = JSON.parse(JSON.stringify(form?.goals || []));

    const existingIndex = goals?.indexOf(goal);

    if (existingIndex !== -1) {
      goals.splice(existingIndex, 1);
    } else {
      goals.push(goal);
    }

    setForm({
      ...form,
      goals
    });
  };

  const toggleSector = (sector) => {
    const sectors = JSON.parse(JSON.stringify(form?.sectors || []));

    const existingIndex = sectors?.indexOf(sector);

    if (existingIndex !== -1) {
      sectors.splice(existingIndex, 1);
    } else {
      sectors.push(sector);
    }

    setForm({
      ...form,
      sectors
    });
  };

  //

  const refreshPaymentMethod = async () => {
    const paymentMethod = await getPaymentMethod();

    setCardInfo(paymentMethod);
  };

  const update = () => {
    if (saving) {
      return;
    }

    if (form?.stripePlan !== account?.stripePlan) {
      setUpdatingPlan(true);

      subscribe({
        accountId: account.id,
        onComplete: async ({ data }) => {
          const { actionRequired, clientSecret } = data;

          if (actionRequired) {
            const { paymentIntent, error } = await stripe.confirmCardPayment(
              clientSecret
            );

            if (error) {
              console.error(error);
              return;
            }
          }

          confirmSubscribe({
            paymentMethodId: account.paymentMethodId,
            subscription: data
          });

          refreshCurrentAccount();

          setTimeout(() => {
            setUpdatingPlan(false);
          }, 500);
        },
        paymentMethodId: account.paymentMethodId,
        planId: form.stripePlan
      });
    }

    setSaving(true);

    updateCurrentAccount(form).then((res) => {
      refreshCurrentAccount();

      setTimeout(() => {
        setSaving(false);
      }, 500);
    });
  };

  // ==========================================================================
  // lifecycle

  useEffect(async () => {
    if (!account?.uid) {
      return;
    }

    if (!cardInfo?.card) {
      refreshPaymentMethod();
    } else if (refreshCard) {
      setRefreshCard(false);

      refreshPaymentMethod();
    }

    if (!transactions?.[0]) {
      const invoices = await getInvoices();
      const visibleTransactions = [];

      if (invoices?.[0]) {
        invoices.forEach((invoice) => {
          if (invoice?.amount_received > 0) {
            visibleTransactions.push(invoice);
          }
        });
      }

      if (visibleTransactions) {
        setTransactions(visibleTransactions);
      }
    }

    setForm({
      goals: account?.goals || [],
      journey: account?.journey || [],
      sectors: account?.sectors || [],
      stripePlan: account.stripePlan || `free`
    });
  }, [account, refreshCard]);

  // ==========================================================================
  // render

  const saveButton = (
    <button
      type="button"
      css={[
        css`
          opacity: ${saving ? `0.5` : `1`};
          pointer-events: ${saving ? `none` : `auto`};

          border-radius: 8px;
        `,
        tw`h-9 relative block px-4 bg-green-dark text-white`
      ]}
      onClick={update}
    >
      <span css={[tw`mt-1 block font-body md:text-b2-md text-b2`]}>
        {saving ? `Saving...` : `Save`}
      </span>
    </button>
  );

  return (
    <>
      {loggedIn && (
        <>
          {/* transaction overlay */}
          <div
            css={[
              css`
                transition: 0.5s ease opacity;

                opacity: ${transactionsActive ? 1 : 0};
                pointer-events: ${transactionsActive ? `auto` : `none`};
              `,
              tw`w-full h-full fixed top-0 right-0 bottom-0 left-0 z-20 flex flex-col items-center justify-center bg-green-dark text-wheat`
            ]}
          >
            <div
              css={[
                css`
                  width: 900px;
                  position: relative;
                `,
                tw``
              ]}
            >
              <header css={[tw`w-full relative text-center`]}>
                <h3 css={[tw`mt-12 mb-12 font-head text-h3 md:text-h3-md`]}>
                  Transaction History
                </h3>

                <button
                  type="button"
                  css={[
                    css`
                      &:hover {
                        text-decoration: underline;
                      }
                    `,
                    tw`absolute top-3 right-0 block`
                  ]}
                  onClick={() => setTransactionsActive(false)}
                >
                  <p css={[tw`font-body text-b1 md:text-b1-md`]}>Close</p>
                </button>
              </header>

              <div
                css={[
                  css`
                    width: 100%;
                    height: 60vh;
                    position: relative;
                  `,
                  tw`overflow-y-scroll border`
                ]}
              >
                {transactions?.[0] && (
                  <table
                    css={[
                      css`
                        width: 100%;
                        height: 100%;
                        position: relative;
                        vertical-align: top;

                        th,
                        td {
                          width: 33.33333%;
                        }
                      `,
                      tw`overflow-y-scroll text-center`
                    ]}
                  >
                    <thead
                      css={[
                        tw`w-full h-12 sticky top-0 right-0 left-0 z-10 bg-wheat text-green-dark`
                      ]}
                    >
                      <tr>
                        <th colSpan="1">Date</th>
                        <th colSpan="1">Description</th>
                        <th colSpan="1">Amount</th>
                      </tr>
                    </thead>

                    <tbody css={[tw`relative`]}>
                      {transactions.map((transaction) => {
                        const createdDate = moment(transaction.created * 1000);

                        return (
                          <tr
                            key={`transaction-${transaction.id}`}
                            css={[tw`h-12 relative border-b border-wheat`]}
                          >
                            <td
                              colSpan="1"
                              css={[tw`font-body text-b1 md:text-b1-md`]}
                            >
                              {createdDate.format(`LL`)}
                            </td>
                            <td
                              colSpan="1"
                              css={[tw`font-body text-b1 md:text-b1-md`]}
                            >
                              {transaction.description}
                            </td>
                            <td
                              colSpan="1"
                              css={[tw`font-body text-b1 md:text-b1-md`]}
                            >
                              £{parseFloat(transaction.amount / 100).toFixed(2)}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          </div>

          {/* tokens overlay */}

          <div
            css={[
              css`
                transition: 0.5s ease opacity;

                opacity: ${purchaseActive ? 1 : 0};
                pointer-events: ${purchaseActive ? `auto` : `none`};
              `,
              tw`w-full h-full fixed top-0 right-0 bottom-0 left-0 z-20 flex flex-col items-center justify-center bg-green-dark text-wheat`
            ]}
          >
            <div
              css={[
                css`
                  width: 900px;
                `,
                tw`relative rounded-md p-6 bg-green-light text-green-dark`
              ]}
            >
              <header css={[tw`w-full relative text-center`]}>
                <h3 css={[tw`mb-6 font-head text-h4 md:text-h4-md`]}>
                  Purchase Tokens
                </h3>

                <div css={[tw`w-12 h-12 absolute top-0 -right-2`]}>
                  <button
                    type="button"
                    css={[
                      css`
                        &:hover {
                          text-decoration: underline;
                        }
                      `,
                      tw`w-full h-full relative block`
                    ]}
                    onClick={() => setPurchaseActive(false)}
                  >
                    <div
                      css={[
                        tw`w-full h-full relative flex items-center justify-end`
                      ]}
                    >
                      <Cross css={[tw`w-8 h-8`]} fill="#305247" />
                    </div>
                  </button>
                </div>
              </header>

              <div
                css={[
                  css`
                    //
                  `,
                  tw`w-full relative flex justify-between rounded-lg`
                ]}
              >
                <article
                  css={[
                    css`
                      width: 32%;
                    `,
                    tw`relative`
                  ]}
                >
                  <div
                    css={[tw`relative p-6 border-green-dark rounded-lg border`]}
                  >
                    <div css={[tw`relative flex`]}>
                      <div css={[tw`w-1/3 relative`]}>
                        <Token
                          css={[
                            css`
                              width: 80%;
                            `,
                            tw`relative block`
                          ]}
                          fill={colours.background}
                        />
                      </div>

                      <div css={[tw`w-2/3 relative`]}>
                        <h4 css={[tw`text-b2 md:text-b2-md font-head`]}>
                          1 Token
                        </h4>

                        <div
                          css={[
                            css`
                              height: 1px;
                            `,
                            tw`w-full relative block mt-3 mb-4 bg-green-dark`
                          ]}
                        />

                        <h4 css={[tw`text-b3 md:text-b3-md font-head`]}>
                          £5.00
                        </h4>
                      </div>
                    </div>
                  </div>

                  <Button
                    _css={[
                      tw`w-full relative rounded-md mt-5 font-body text-button md:text-button-md text-white`
                    ]}
                    color="green-dark"
                    onClick={() => {
                      buyTokens(TOKEN1);
                    }}
                    text="Purchase tokens"
                    type="button"
                  />
                </article>

                <article
                  css={[
                    css`
                      width: 32%;
                    `,
                    tw`relative`
                  ]}
                >
                  <div
                    css={[tw`relative p-6 border-green-dark rounded-lg border`]}
                  >
                    <div css={[tw`relative flex`]}>
                      <div css={[tw`w-1/3 relative`]}>
                        <Token
                          css={[
                            css`
                              width: 80%;
                            `,
                            tw`relative block`
                          ]}
                          fill={colours.background}
                        />
                      </div>

                      <div css={[tw`w-2/3 relative`]}>
                        <h4 css={[tw`text-b2 md:text-b2-md font-head`]}>
                          2 Tokens
                        </h4>

                        <div
                          css={[
                            css`
                              height: 1px;
                            `,
                            tw`w-full relative block mt-3 mb-4 bg-green-dark`
                          ]}
                        />

                        <h4 css={[tw`text-b3 md:text-b3-md font-head`]}>
                          £10.00
                        </h4>
                      </div>
                    </div>
                  </div>

                  <Button
                    _css={[
                      tw`w-full relative rounded-md mt-5 font-body text-button md:text-button-md text-white`
                    ]}
                    color="green-dark"
                    onClick={() => {
                      buyTokens(TOKEN2);
                    }}
                    text="Purchase tokens"
                    type="button"
                  />
                </article>

                <article
                  css={[
                    css`
                      width: 32%;
                    `,
                    tw`relative`
                  ]}
                >
                  <div
                    css={[tw`relative p-6 border-green-dark rounded-lg border`]}
                  >
                    <div css={[tw`relative flex`]}>
                      <div css={[tw`w-1/3 relative`]}>
                        <Token
                          css={[
                            css`
                              width: 80%;
                            `,
                            tw`relative block`
                          ]}
                          fill={colours.background}
                        />
                      </div>

                      <div css={[tw`w-2/3 relative`]}>
                        <h4 css={[tw`text-b2 md:text-b2-md font-head`]}>
                          5 Tokens
                        </h4>

                        <div
                          css={[
                            css`
                              height: 1px;
                            `,
                            tw`w-full relative block mt-3 mb-4 bg-green-dark`
                          ]}
                        />

                        <h4 css={[tw`text-b3 md:text-b3-md font-head`]}>
                          £25.00
                        </h4>
                      </div>
                    </div>
                  </div>

                  <Button
                    _css={[
                      tw`w-full relative rounded-md mt-5 font-body text-button md:text-button-md text-white`
                    ]}
                    color="green-dark"
                    onClick={() => {
                      buyTokens(TOKEN5);
                    }}
                    text="Purchase tokens"
                    type="button"
                  />
                </article>
              </div>
            </div>
          </div>

          {/* main content */}

          <section
            css={[
              css`
                ${colourTransitionCSS};

                padding-top: 5rem;
                color: ${colours?.secondary};

                ${MEDIA_QUERIES.desktop} {
                  padding-top: 10rem;
                }
              `,
              tw`relative bg-grey-tint`
            ]}
          >
            <Grid>
              <div
                css={[
                  tw`col-span-12 md:col-span-5 md:h-screen md:sticky md:top-32 md:left-0`
                ]}
              >
                <div css={[tw`w-full md:w-4/5 relative`]}>
                  <AccountCard
                    onPurchase={() => setPurchaseActive(true)}
                    onTransactions={() => setTransactionsActive(true)}
                  />
                </div>
              </div>

              {/* // */}

              <div
                css={[tw`col-span-12 md:col-span-6 relative pb-12 md:pb-24`]}
              >
                <section css={[tw`w-full relative pt-16 md:pt-0 mb-20`]}>
                  <header css={[tw`w-full relative border-b`]}>
                    <h2 css={[tw`pb-3 font-body text-h3 md:text-h4-md`]}>
                      Account details
                    </h2>
                  </header>

                  <label
                    htmlFor="profile_pronouns"
                    css={[tw`relative block mt-8 mb-6`]}
                  >
                    <span
                      css={[
                        tw`block mb-2 font-body text-b2 md:text-b2-md italic`
                      ]}
                    >
                      Preferred pronouns
                    </span>

                    <input
                      id="profile_pronouns"
                      name="pronouns"
                      css={[
                        tw`w-full relative block pt-2 pr-1 pb-1 pl-1 font-body text-b2 md:text-b2-md`
                      ]}
                      placeholder="Preferred pronouns"
                      onChange={onChange}
                      defaultValue={account?.pronouns}
                      type="text"
                    />
                  </label>

                  <label
                    htmlFor="profile_firstName"
                    css={[tw`relative block mt-8 mb-6`]}
                  >
                    <span
                      css={[
                        tw`block mb-2 font-body text-b2 md:text-b2-md italic`
                      ]}
                    >
                      Preferred name
                    </span>

                    <input
                      id="profile_firstName"
                      name="firstName"
                      css={[
                        tw`w-full relative block pt-2 pr-1 pb-1 pl-1 font-body text-b2 md:text-b2-md`
                      ]}
                      placeholder="Preferred name"
                      onChange={onChange}
                      defaultValue={account?.firstName}
                      type="text"
                    />
                  </label>

                  <label
                    htmlFor="profile_lastName"
                    css={[tw`relative block mt-8 mb-6`]}
                  >
                    <span
                      css={[
                        tw`block mb-2 font-body text-b2 md:text-b2-md italic`
                      ]}
                    >
                      Surname
                    </span>

                    <input
                      id="profile_lastName"
                      name="lastName"
                      css={[
                        tw`w-full relative block pt-2 pr-1 pb-1 pl-1 font-body text-b2 md:text-b2-md`
                      ]}
                      placeholder="Surname"
                      onChange={onChange}
                      defaultValue={account?.lastName}
                      type="text"
                    />
                  </label>

                  <div css={[tw`relative block mt-8 mb-6`]}>
                    <span
                      css={[
                        tw`block mb-2 font-body text-b2 md:text-b2-md italic`
                      ]}
                    >
                      Email
                    </span>

                    <p
                      css={[
                        tw`w-full relative block pt-2 pr-1 pb-1 pl-1 font-body text-b2 md:text-b2-md`
                      ]}
                    >
                      {authUser?.email}
                    </p>
                  </div>

                  <div css={[tw`relative block mt-8 mb-6`]}>
                    <span
                      css={[
                        tw`block mb-2 font-body text-b2 md:text-b2-md italic`
                      ]}
                    >
                      Password
                    </span>

                    <button
                      type="button"
                      css={[
                        css`
                          opacity: ${saving ? `0.5` : `1`};
                          pointer-events: ${saving ? `none` : `auto`};
                          background: transparent;
                          border-radius: 8px;
                        `,
                        tw`h-10 relative block px-5 border border-green-dark text-green-dark`
                      ]}
                      onClick={resetUserPassword}
                    >
                      <span
                        css={[tw`mt-1 block font-body md:text-b2-md text-b2`]}
                      >
                        Send password update email
                      </span>
                    </button>
                  </div>

                  {saveButton}
                </section>

                {/* // */}

                <section css={[tw`w-full relative pt-16 md:pt-0 mb-20`]}>
                  <header css={[tw`w-full relative border-b`]}>
                    <h2 css={[tw`pb-3 font-body text-h4 md:text-h4-md`]}>
                      Your profile
                    </h2>
                  </header>

                  <article css={[tw`relative mt-8 mb-6`]}>
                    <label
                      htmlFor="profile_jobTitle"
                      css={[tw`relative block mt-8 mb-6`]}
                    >
                      <span
                        css={[
                          tw`block mb-2 font-body text-b2 md:text-b2-md italic`
                        ]}
                      >
                        Job title
                      </span>

                      <input
                        id="profile_jobTitle"
                        name="jobTitle"
                        css={[
                          tw`w-full relative block pt-2 pr-1 pb-1 pl-1 font-body text-b2 md:text-b2-md`
                        ]}
                        placeholder="Job title"
                        onChange={onChange}
                        defaultValue={account?.jobTitle}
                        type="text"
                      />
                    </label>

                    <label
                      htmlFor="profile_companyName"
                      css={[tw`relative block mt-8 mb-6`]}
                    >
                      <span
                        css={[
                          tw`block mb-2 font-body text-b2 md:text-b2-md italic`
                        ]}
                      >
                        Company name
                      </span>

                      <input
                        id="profile_companyName"
                        name="companyName"
                        css={[
                          tw`w-full relative block pt-2 pr-1 pb-1 pl-1 font-body text-b2 md:text-b2-md`
                        ]}
                        placeholder="Company name"
                        onChange={onChange}
                        defaultValue={account?.companyName}
                        type="text"
                      />
                    </label>

                    <label
                      htmlFor="profile_companyWebsite"
                      css={[tw`relative block mt-8 mb-6`]}
                    >
                      <span
                        css={[
                          tw`block mb-2 font-body text-b2 md:text-b2-md italic`
                        ]}
                      >
                        Company website
                      </span>

                      <input
                        id="profile_companyWebsite"
                        name="companyWebsite"
                        css={[
                          tw`w-full relative block pt-2 pr-1 pb-1 pl-1 font-body text-b2 md:text-b2-md`
                        ]}
                        placeholder="Company website"
                        onChange={onChange}
                        defaultValue={account?.companyWebsite}
                        type="text"
                      />
                    </label>

                    {/* // */}

                    <div css={[tw`relative pt-2`]}>
                      <p css={[tw`font-body text-b2 md:text-b2-md italic`]}>
                        Social media
                      </p>

                      <label
                        htmlFor="profile_linkedin"
                        css={[
                          tw`relative flex items-center justify-between mt-5`
                        ]}
                      >
                        <div
                          css={[
                            css`
                              width: 2rem;
                              height: 2rem;

                              ${MEDIA_QUERIES.desktop} {
                                width: 3vw;
                                height: 3vw;
                              }
                            `,
                            tw`rounded-full overflow-hidden flex items-center justify-center bg-green-light`
                          ]}
                        >
                          <LinkedInIcon
                            css={[tw`w-full h-full`]}
                            fill="#305247"
                          />
                        </div>

                        <input
                          id="profile_linkedin"
                          name="linkedin"
                          css={[
                            css`
                              width: calc(100% - 2.5rem);

                              ${MEDIA_QUERIES.desktop} {
                                width: calc(100% - 4vw);
                              }
                            `,
                            tw`h-12 relative block px-4 rounded-md font-body text-b2 md:text-b2-md`
                          ]}
                          onChange={onChange}
                          placeholder="Link to Linkedin"
                          type="text"
                          defaultValue={account?.linkedin}
                        />
                      </label>

                      <label
                        htmlFor="profile_instagram"
                        css={[
                          tw`relative flex items-center justify-between mt-5`
                        ]}
                      >
                        <div
                          css={[
                            css`
                              width: 2rem;
                              height: 2rem;

                              ${MEDIA_QUERIES.desktop} {
                                width: 3vw;
                                height: 3vw;
                              }
                            `,
                            tw`rounded-full flex items-center justify-center bg-green-dark`
                          ]}
                        >
                          <InstagramIcon css={[tw`w-3/5`]} fill="#DEEFCB" />
                        </div>

                        <input
                          id="profile_instagram"
                          name="instagram"
                          css={[
                            css`
                              width: calc(100% - 2.5rem);

                              ${MEDIA_QUERIES.desktop} {
                                width: calc(100% - 4vw);
                              }
                            `,
                            tw`h-12 relative block px-4 rounded-md font-body text-b2 md:text-b2-md`
                          ]}
                          onChange={onChange}
                          placeholder="Link to Instagram"
                          type="text"
                          defaultValue={account?.instagram}
                        />
                      </label>
                    </div>

                    {/* // */}

                    <div css={[tw`relative block mt-12`]}>
                      <p
                        css={[
                          tw`block mb-2 font-body italic text-b2 md:text-b2-md`
                        ]}
                      >
                        Industry/sectors
                      </p>

                      <div css={[tw`relative flex flex-wrap`]}>
                        {allSectors.map((sector) => {
                          const background = lowImpactMode
                            ? colours?.foreground
                            : colours?.background;

                          return (
                            <div key={sector}>
                              <Button
                                _css={[
                                  css`
                                    transition: 0.3s ease opacity;
                                    border: 1px solid ${background} !important;

                                    ${MEDIA_QUERIES.hoverable} {
                                      &:hover {
                                        opacity: 0.3;
                                      }
                                    }
                                  `,
                                  tw`mr-4 mb-4 px-4`
                                ]}
                                color={
                                  form?.sectors?.includes(sector)
                                    ? background
                                    : `transparent`
                                }
                                onClick={() => {
                                  toggleSector(sector);
                                }}
                                text={sector}
                              />
                            </div>
                          );
                        })}
                      </div>
                    </div>

                    {/* // */}

                    <div css={[tw`relative pt-8`]}>
                      <JourneySlider
                        defaultValue={account?.journey}
                        heading="Your point in your journey"
                        items={slide2JourneyItems}
                        onChange={onJourneyChange}
                      />
                    </div>

                    {/* // */}

                    <div css={[tw`relative block`]}>
                      <p
                        css={[tw`block font-body italic text-b2 md:text-b2-md`]}
                      >
                        Outcomes
                      </p>

                      <div
                        css={[
                          css`
                            width: 100%;

                            ${MEDIA_QUERIES.desktop} {
                              width: 120%;
                            }
                          `,
                          tw`relative flex flex-wrap justify-between md:justify-start`
                        ]}
                      >
                        {allGoals.map((goal) => {
                          const background = lowImpactMode
                            ? colours?.foreground
                            : colours?.background;
                          const foreground = lowImpactMode
                            ? colours?.background
                            : colours?.foreground;

                          const active = form?.goals?.includes(goal);

                          const iconCSS = [
                            css`
                              transition: 0.3s
                                cubic-bezier(0.215, 0.61, 0.355, 1) fill;
                            `,
                            tw`w-3/5`
                          ];

                          let icon;

                          switch (goal) {
                            case `Improve my confidence`:
                              icon = (
                                <ConfidenceIcon
                                  css={iconCSS}
                                  fill={active ? foreground : background}
                                />
                              );

                              break;

                            case `Learn new skills`:
                              icon = (
                                <TokenIcon
                                  css={iconCSS}
                                  fill={active ? foreground : background}
                                />
                              );

                              break;

                            case `Develop my product`:
                              icon = (
                                <StackIcon
                                  css={iconCSS}
                                  fill={active ? foreground : background}
                                />
                              );

                              break;

                            case `Join a community`:
                              icon = (
                                <StackIcon
                                  css={iconCSS}
                                  fill={active ? foreground : background}
                                />
                              );

                              break;

                            default:
                              break;
                          }

                          //

                          return (
                            <div
                              key={`goal-${goal}`}
                              css={[
                                css`
                                  color: ${active ? foreground : background};

                                  width: calc(50% - 0.25rem);
                                  height: auto;
                                  margin-top: 0.5rem;

                                  ${MEDIA_QUERIES.desktop} {
                                    width: 25%;
                                    height: 12.5vw;
                                    margin-right: 2vw;
                                    margin-top: 1vw;
                                  }
                                `,
                                tw`relative block`
                              ]}
                            >
                              <button
                                type="button"
                                css={[
                                  css`
                                    transition: 0.3s ease opacity;

                                    padding: 5%;
                                    background: ${active
                                      ? background
                                      : `transparent`};
                                    border: 1px solid ${background};

                                    ${MEDIA_QUERIES.hoverable} {
                                      &:hover {
                                        opacity: 0.3 !important;
                                      }
                                    }
                                  `,
                                  tw`w-full h-full relative block overflow-hidden rounded-xl`
                                ]}
                                onClick={() => {
                                  toggleGoal(goal);
                                }}
                              >
                                <div
                                  css={[
                                    tw`w-full h-full relative flex flex-col items-center justify-center`
                                  ]}
                                >
                                  {icon}

                                  <p
                                    css={[
                                      css`
                                        line-height: 90% !important;
                                        margin-top: 1rem;
                                        margin-bottom: 1rem;

                                        ${MEDIA_QUERIES.hoverable} {
                                          height: 2vw;
                                          margin-top: 1vw;
                                          margin-bottom: 0;
                                        }
                                      `,
                                      tw`w-3/4 md:w-full mx-auto text-b2 md:text-b2-md font-body`
                                    ]}
                                  >
                                    {goal}
                                  </p>
                                </div>
                              </button>
                            </div>
                          );
                        })}
                      </div>
                    </div>

                    {/* // */}

                    <label
                      htmlFor="profile_about"
                      css={[tw`relative block mt-8 mb-6`]}
                    >
                      <span
                        css={[
                          tw`block mb-2 font-body text-b2 md:text-b2-md italic`
                        ]}
                      >
                        About you
                      </span>

                      <textarea
                        id="profile_about"
                        name="about"
                        css={[
                          css`
                            border-radius: 8px;
                            overflow: hidden;
                          `,
                          tw`w-full h-32 relative block pt-2 pr-1 pb-1 pl-1 font-body text-b2 md:text-b2-md`
                        ]}
                        placeholder="About"
                        onChange={(e) =>
                          setForm({
                            ...form,
                            [e.currentTarget.name]: e.currentTarget.value
                          })
                        }
                        defaultValue={account?.about}
                        type="text"
                      />
                    </label>
                  </article>

                  <div css={[tw`relative pt-4`]}>{saveButton}</div>
                </section>

                {/* // */}

                <section css={[tw`w-full relative pt-16 md:pt-0 mb-20`]}>
                  <header css={[tw`w-full relative border-b`]}>
                    <h2 css={[tw`pb-3 font-body text-h4 md:text-h4-md`]}>
                      Payment details
                    </h2>
                  </header>

                  <div css={[tw`relative mt-8`]}>
                    <button
                      type="button"
                      css={[
                        css`
                          ${colourTransitionCSS};

                          opacity: ${updatingPlan ? 0.5 : 1};
                          pointer-events: ${updatingPlan ? `none` : `auto`};

                          ${lowImpactMode
                            ? `
                            background: ${
                              form?.stripePlan === `free`
                                ? colours.foreground
                                : `transparent`
                            };
                            color: ${
                              form?.stripePlan === `free`
                                ? colours.background
                                : colours.foreground
                            };
                          `
                            : `
                          background: ${
                            form?.stripePlan === `free`
                              ? colours.background
                              : `transparent`
                          };
                          color: ${
                            form?.stripePlan === `free`
                              ? colours.foreground
                              : colours.background
                          };
                        `}
                        `,
                        tw`w-full relative block mb-4 pt-4 pr-10 pb-4 border rounded-xl`
                      ]}
                      onClick={() => {
                        if (updatingPlan) {
                          return;
                        }

                        setForm({
                          ...form,
                          stripePlan: `free`
                        });
                      }}
                    >
                      <div css={[tw`w-full relative flex items-center`]}>
                        <div
                          css={[
                            tw`w-1/6 relative flex items-start justify-center`
                          ]}
                        >
                          <Plan
                            fill={
                              form?.stripePlan === `free`
                                ? colours.foreground
                                : colours.background
                            }
                            css={[tw`w-2/3 relative block`]}
                          />
                        </div>

                        <div css={[tw`w-5/6 relative text-left`]}>
                          <h3
                            css={[
                              tw`w-full relative font-body text-h4 md:text-h4-md`
                            ]}
                          >
                            Community Membership
                          </h3>

                          <p
                            css={[
                              tw`w-full relative font-body text-b1 md:text-b1-md italic`
                            ]}
                          >
                            Free
                          </p>
                        </div>
                      </div>
                    </button>

                    <button
                      type="button"
                      css={[
                        css`
                          ${colourTransitionCSS};

                          opacity: ${updatingPlan || !cardInfo?.card ? 0.5 : 1};
                          pointer-events: ${updatingPlan || !cardInfo?.card
                            ? `none`
                            : `auto`};

                          ${lowImpactMode
                            ? `
                            background: ${
                              form?.stripePlan === `premium`
                                ? colours.foreground
                                : `transparent`
                            };
                            color: ${
                              form?.stripePlan === `premium`
                                ? colours.background
                                : colours.foreground
                            };
                          `
                            : `
                          background: ${
                            form?.stripePlan === `premium`
                              ? colours.background
                              : `transparent`
                          };
                          color: ${
                            form?.stripePlan === `premium`
                              ? colours.foreground
                              : colours.background
                          };
                          `}
                        `,
                        tw`w-full relative block mb-4 pt-4 pr-10 pb-4 border rounded-xl`
                      ]}
                      onClick={() => {
                        if (updatingPlan) {
                          return;
                        }

                        setForm({
                          ...form,
                          stripePlan: `premium`
                        });
                      }}
                    >
                      <div css={[tw`w-full relative flex items-center`]}>
                        <div
                          css={[
                            tw`w-1/6 relative flex items-start justify-center`
                          ]}
                        >
                          <Plan
                            fill={
                              form?.stripePlan === `premium`
                                ? colours.foreground
                                : colours.background
                            }
                            css={[tw`w-2/3 relative block`]}
                          />
                        </div>

                        <div css={[tw`w-5/6 relative text-left`]}>
                          <h3
                            css={[
                              tw`w-full relative font-body text-h4 md:text-h4-md`
                            ]}
                          >
                            Premium Membership
                          </h3>

                          <p
                            css={[
                              tw`w-full relative font-body text-b1 md:text-b1-md italic`
                            ]}
                          >
                            £7.50 per month
                            {!cardInfo?.card
                              ? ` (please enter your card information below)`
                              : ``}
                          </p>
                        </div>
                      </div>
                    </button>

                    {saveButton}

                    {account?.stripePlan === `premium` && (
                      <ul css={[tw`relative mt-6`]}>
                        {account.subscriptionCreated && (
                          <li css={[tw`relative mt-1 mb-1`]}>
                            <p
                              css={[
                                tw`w-full relative font-body text-b2 md:text-b2-md`
                              ]}
                            >
                              <span css={[tw`italic`]}>
                                Subscription started:{` `}
                              </span>
                              <span>
                                {moment
                                  .unix(account.subscriptionCreated)
                                  .format(`LLLL`)}
                              </span>
                            </p>
                          </li>
                        )}

                        {account.subscriptionCurrentPeriodStart && (
                          <li css={[tw`relative mt-1 mb-1`]}>
                            <p
                              css={[
                                tw`w-full relative font-body text-b2 md:text-b2-md`
                              ]}
                            >
                              <span css={[tw`italic`]}>
                                Last payment date:{` `}
                              </span>
                              <span>
                                {moment
                                  .unix(account.subscriptionCurrentPeriodStart)
                                  .format(`LLLL`)}
                              </span>
                            </p>
                          </li>
                        )}

                        {account.subscriptionCurrentPeriodEnd && (
                          <li css={[tw`relative mt-1 mb-1`]}>
                            <p
                              css={[
                                tw`w-full relative font-body text-b2 md:text-b2-md`
                              ]}
                            >
                              <span css={[tw`italic`]}>
                                Next payment date:{` `}
                              </span>
                              <span>
                                {moment
                                  .unix(account.subscriptionCurrentPeriodEnd)
                                  .format(`LLLL`)}
                              </span>
                            </p>
                          </li>
                        )}
                      </ul>
                    )}

                    <div css={[tw`relative mt-12`]}>
                      {cardInfo?.card && (
                        <ul>
                          <li css={[tw`relative mt-2 mb-6`]}>
                            <p
                              css={[
                                tw`block mb-2 font-body text-b2 md:text-b2-md italic`
                              ]}
                            >
                              Credit card number
                            </p>
                            <p
                              css={[
                                tw`block mb-2 font-body text-b2 md:text-b2-md`
                              ]}
                            >
                              xxxx xxxx xxxx {cardInfo.card.last4}
                            </p>
                          </li>

                          <li css={[tw`relative mt-2 mb-6`]}>
                            <p
                              css={[
                                tw`block mb-2 font-body text-b2 md:text-b2-md italic`
                              ]}
                            >
                              Card type
                            </p>
                            <p
                              css={[
                                tw`block mb-2 font-body text-b2 md:text-b2-md`
                              ]}
                            >
                              {cardInfo.card.brand.toUpperCase()}
                            </p>
                          </li>

                          <li css={[tw`relative mt-2 mb-6`]}>
                            <p
                              css={[
                                tw`block mb-2 font-body text-b2 md:text-b2-md italic`
                              ]}
                            >
                              Expiry date
                            </p>
                            <p
                              css={[
                                tw`block mb-2 font-body text-b2 md:text-b2-md`
                              ]}
                            >
                              {`${cardInfo.card.exp_month}/${cardInfo.card.exp_year}`}
                            </p>
                          </li>
                        </ul>
                      )}

                      {account?.id && (
                        <div css={[tw`relative pt-6`]}>
                          <p
                            css={[
                              tw`block mb-6 font-body text-b2 md:text-b2-md italic`
                            ]}
                          >
                            Update card details
                          </p>

                          <StripePaymentCapture
                            accountId={account.id}
                            buttonText="Update"
                            onComplete={(paymentMethodId) => {
                              setSaving(true);

                              subscribe({
                                accountId: account.id,
                                onComplete: async ({ data }) => {
                                  const { actionRequired, clientSecret } = data;

                                  setSaving(false);

                                  if (actionRequired) {
                                    const { paymentIntent, error } =
                                      await stripe.confirmCardPayment(
                                        clientSecret
                                      );

                                    if (error) {
                                      console.error(error);
                                      return;
                                    }
                                  }

                                  confirmSubscribe({
                                    paymentMethodId,
                                    subscription: data
                                  });

                                  setRefreshCard(true);
                                },
                                paymentMethodId,
                                trial: 0
                              });
                            }}
                          />
                        </div>
                      )}
                    </div>

                    {sanityAccountPage?.cancellationHeading &&
                      sanityAccountPage?.cancellationNotice && (
                        <div css={[tw`relative block mt-12`]}>
                          <span
                            css={[
                              tw`block mb-2 font-body text-b2 md:text-b2-md italic`
                            ]}
                          >
                            {sanityAccountPage.cancellationHeading}
                          </span>

                          <p
                            css={[
                              tw`w-full md:w-4/5 relative block pt-2 pr-1 pb-1 pl-1 font-body text-b2 md:text-b2-md`
                            ]}
                          >
                            <span
                              dangerouslySetInnerHTML={{
                                __html: sanityAccountPage.cancellationNotice
                              }}
                            />
                          </p>
                        </div>
                      )}
                  </div>
                </section>
              </div>
            </Grid>
          </section>
        </>
      )}

      <Footer />
    </>
  );
};

export default Profile;
